import React from 'react';

import Layout from '../components/Layout';

import female from '../assets/images/female.png';
import male from '../assets/images/male.png';
import kaing_su_wai from '../assets/images/khine_su_wai.png';
import khin_mya_thwin from '../assets/images/khin_mya_thwin.png';

import htet_htet_aung from '../assets/images/htet_htet_aung.png';
import nang_ei_mon from '../assets/images/nang_ei_mon.png';
import swe_zin_oo from '../assets/images/swe_zin_oo.png';
import san_san from '../assets/images/san_san.png';
import sithu_kyaw from '../assets/images/sithu_kyaw.png';
import hsu_wai from '../assets/images/hsu_wai.png';
import dzaky from '../assets/images/dzaky.png';
import chue_ei_mon from '../assets/images/chue_ei_mon.png';
import hinn_hinn_lwin from '../assets/images/hinn_hinn_lwin.png';
import waddy from '../assets/images/waddy.png';


const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Feedbacks</h2>
          <p>What people said about Min Thant</p>
        </div>
      </header>

      <div className="wrapper">


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={hinn_hinn_lwin} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Hinn Hinn Lwin
                <br/>
                <span>Accountant</span>      
            </h2>
            <p>
              I really like this planning Min Khant did for me, which give
              the benefit not only for insured coverage but also cash value as like the investment. Accountant As you choose the best plan for me, I would like to say thanks you. Appreciate for your service!.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={waddy} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Waddy
                <br/>
                <span>IT Analyst</span>            
            </h2>
            <p>
            Min Khant is a capable financial advisor. He
            genuinely cares about his customer, and he
            explained everything to them in a clear and
            sameless manner. He guided me through the
            entire financial planning process, analyzed my
            account, and recommended the best course of
            action
            </p>
          </div>
        </div>
      </section>


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={dzaky} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Dzaky&Mom
                <br/>
            </h2>
            <p>
            Min has been very helpful through the
            entire planning session and provide excellent
            service relation with our family. We have not just
            been client-agent, but also friends. Definitely,
            Min would be a very recommended person to
            work with professionally and as a friend.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={chue_ei_mon} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                CHUE EIMON
                <br/>
                <span>Engineer</span>            
            </h2>
            <p>
            'Insurance? First thing comes to my mind when i heard Insurance is "Is it necessary? Is it a waste of money ?" All of my thoughts changed after I met with Min Khant. He
            explained to me about advantages , benefits and policy
            of insurance .He is really a good friend and also good adviser .
            I would like to say Thanks to AIA and Min Khant.
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={nang_ei_mon} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Nang Ei Mon
                <br/>
                <span>28</span> | <span>Engineer</span>
            </h2>
            <p>
            Planning with Min was smooth and easy as a breeze. He did not rush
            anything and took time to explain things rather thoroughly and also provided in layman termsfor some of the more 
            complicated bits. It helped me understand what I really needed in finicial plainning
            <br/>
            Min is candid and transparent when it comes to assessing and propsoing the right insurance for his clients.
            The way he introduces himself and communicates shows me that hes highly passionate about his work and makes me feel confident to approach him for anything
            I'm unsure of or want to know with the process.
            <br/>
            I wold definitely recommend Min to any of my friends or qcquaitances looking to get finacial insurance or upgrade
            whatever they have to the moment for a finer financial scheme and brilliant service.
            <br/>
            Min provied me with the variaous options and possibilites to grow and protect my fiance. I've gained
            knowledgeable insights for a better finace plainning. I feel assured and eager to move forward with Min as my financial plainnig agent.
            Thank You!
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={swe_zin_oo} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Swe Zin Oo
                <br/>
                <span></span>            
            </h2>
            <p>
            What you like about the planning process
            -I like him initiating everything start from beginning. I was totally
            unfamiliar with insurance and he first introduce
            me about insurance just brief for first meeting. And then start from
            that he slowly explain about that step by step so I am not confuse
            and overwhelm by insurance. What you like about me as a
            professional and a friend.
            -I like him as knowing very well
            about his job and so passionate about it. As a friend, I find it he is friendly, honest, active and social person.
            Why you would recommend me to your friends when it comes to financial planning?
            -I think he has very good knowledge about insurance and he can initiate and share about financial plan very well based on different pro-folio and situation.How have I value added to your knowledge in terms of financial planning.
            -I am aware that I have less worry about my money when it come to my health problems if I have good financial plan
            </p>
          </div>
        </div>
      </section>


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={female} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Htwe Ei Ei Lwin
                <br/>
                <span>Accountant</span>
            </h2>
            <p>
            Min Khant have managed to make the entire planning process smooth and easy to understand.
            He is very professional in advising me with different
            products that would fits my needs in the area perfectly
            I would recommend him as he explained to me the
            product clearly and even made nomination for my life
            policy as a form of service The take away I have from
            him was his emergency cash account concept which I could relate and understand.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={san_san} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                San San
                <br/>
                <span>31</span> | <span>Accountant</span>
            
            </h2>
            <p>
            I would like to express my gratitude for Min Khant for my insurance planning. Before hand, I did not know much about
            healthcare insurance ,after  talking with Min Khant I have gain more knowledge about finance and healthcare insurance Thanks to his recommendations as a friend and explaining in details about issues while being very
            professional. I would definitely recommend Min khant and AIA to
            other people as it was convenient and pleasant experience.
            </p>
          </div>
        </div>
      </section>


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={sithu_kyaw} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Sithu Kyaw
                <br/>
                <span>29</span> | <span>IT Analyst</span>
            </h2>
            <p>
            Min looks at overall aspect of client existing portfolios. Make summary view of what is already present and what areas could be further enhanced. Min is sincere and respectful advisor.
            He is also professional, cool and easygoing as a friend.
            Min truly care aboutthe well-being and have life stability of clients.
            Having the right mindset and the right approach positive outcomes
            attained for both parties in financial planning.
            Min's understanding of individual client preferences and needs. Being
            able to suggest customise recommendations allows me to know more about differentfinancial
            planning products and their purposes.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={female} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Lily  Aung
                <br/>
                <span>Software Engineer</span>            
            </h2>
            <p>
            Min Khant is very polite, friendly, and helpful. He was very informative on the different insurance plans and explained
            those in detail.
            He has been responsive to my requests, explained all my options, and
            made the experience stress-free. It's been great working with him on all my
            insurance needs. I have been thoroughly impressed with Min Khant as my agent and I am very
            grateful for all his help.
            One take away I have from him was his emergency cash account concept which I could relate and understand.
            </p>
          </div>
        </div>
      </section>


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={kaing_su_wai} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Khine Su Wai
                <br/>
                <span>28</span> | <span>Engineer</span>
            </h2>
            <p>
            The whole process was informative and easy to
            understand. Min patiently explains the new and difficult
            terms in bite sizes for me to understand and make an
            informed decision.He responds all my questions
            promptly. I can see that he’s passionate about what he’s doing
            and able to tailor the products according to clients’ needs.
            I would recommend him if you would like to have a chat and
            explore on how you can improve managing finances and plans for
            the future.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={htet_htet_aung} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Htet Htet Aung
                <br/>
                <span>Accountant</span>
            
            </h2>
            <p>
            Min Khant is awesome insurance agent that we
            have ever meet. He assisted us with the changing from
            other insurance company to AIA. He also advised the useful
            plans for my mom and dad.Not only he go extra mile to
            help change the CPF usage from my own account instead of my father but also
            he gave really good advices for suitable plans.
            While he was helping us with the changing and buying accident plan, he
            made effort and came down to our house eventhough it's
            late and explained us clearly. He is very hardworking, kind and
            understanding person.That's why I recommended him on my Facebook page
            and keep refering to my friends and relatives.
            </p>
          </div>
        </div>
      </section>


      
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={hsu_wai} alt="" />
          </a>
          <div className="content">
            <h2 className="major">              
                Hsu Wai
                <br/>
                <span>Software Engineer</span>
            </h2>
            <p>
            Before I met Min khant, I wasn’t the type of the person who was very engaged in financial planning and investments. He is
            professional, and his explanation impressed me of the
            importance to have an emergecy cash acoount sine our first
            meeting. It also helped me to realise what I needed in terms
            of financial planning. Min Khant is someone I would
            strongly recommed to anyone since he is very patient and
            professional when it comes to assessing and proposing the
            best financial palns. He was able to provide a detailed
            assessment of my financial needs and retirement goals in
            just three consultations. He presented a variiety of options
            of acheving them, explaning each option of how iit is good
            for me. He also gave me enough time to think about the optiona
            and wasn’t pushy at all. Overall he helped the entire planning preocess go very smoothly.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={male} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Lim Meng Yew
                <br/>
                <span>SAF</span>            
            </h2>
            <p>
            Min Khant is very patient in helping me compare the different CI plans across the insurers in Singapore.
            His respond is fast and effective. His service was also good. I would recognize his effort and his service.
            Therefore, I recommend him to my wife and my two sons to do up their critical illness coverage with him.
            I recommend people to do plainnig with him as he is very sincere and patient.
            He also done up the nomination assignment for my family and myself.
            </p>
          </div>
        </div>
      </section>

      
      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={khin_mya_thwin} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
                Khin Mya Thwin & U Thu Ya
                <br/>
                <span>50</span> | <span>Accountant</span>

            </h2>
            <p>
            Thank you for getting to know about insurance, and for his patience in explaining all the opportunities, so I contacted
            Min Khant and entered the insurances.
            Insurance အကြောင်းတွေသေချာ
            သိခွင့် ရခဲ့တဲ့အတွက် ကျေးဇူးတင်ပါတယ် သူ့ရဲ့ စိတ်ရှည်စွာည်စွာ ရှင်းပြပေး တဲ့အတွက်လည်း အခွင့်အင့် ရေးတွေ အား လုံး တိတိကျ ကျ သိ ခွင့်ရင့်တယ်တဲ့အတွက် Min Khant နဲ့ဆက်သွယ်ပြီး insurancesဝင်ခဲ့ပါ တ
            </p>
          </div>
        </div>
      </section>

      

      </div>
    </section>
  </Layout>
);

export default IndexPage;
